import React from 'react';

import { CompanyWebsitesMetadataInterface } from '@/utils/helpers/types';

import { CompanyWebsites } from '../CompanyWebsites';

const RenderCompanyWebsites = ({ websites, title }: CompanyWebsitesMetadataInterface) => {
	return <CompanyWebsites title={title} data={websites} />;
};

export default RenderCompanyWebsites;
