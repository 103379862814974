import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar } from '@pickleballinc/react-ui';
import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';

import { LEADERBOARD_DEFAULT_TAB_VALUE, LEADERBOARD_TAB_OPTIONS } from '@/components/RenderRankings/constants';
import { RankingInterfaceV2 } from '@/components/RenderRankings/types';
import getCDNURL from '@/utils/helpers/getCDNURL';
import { LeaderboardOption } from '@/utils/helpers/types';

export interface RenderRankingsProps {
	rankings: RankingInterfaceV2[];
	metadata: LeaderboardOption;
}

const RenderRankings = ({ rankings, metadata }: RenderRankingsProps) => {
	const [ranking, setRanking] = useState<{ value: number; gender: string }>({
		value: metadata.tabValue || LEADERBOARD_DEFAULT_TAB_VALUE.value,
		gender: metadata.tabGender || LEADERBOARD_DEFAULT_TAB_VALUE.gender
	});
	const [stopChangingTabs, setStopChangingTabs] = useState(false);

	const intervalRef = useRef<NodeJS.Timeout | null>(null);

	const { data: rankingsData, isFetching } = useQuery(
		['rankings', ranking.value, ranking.gender],
		async () => {
			const response = await fetch(`/api/v2/rankings?divisionType=${ranking.value}&gender=${ranking.gender}`, {
				method: 'GET'
			});
			const result: { data: RankingInterfaceV2[]; statusCode: number; error?: string } = await response.json();
			return result.data;
		},
		{
			initialData: rankings,
			enabled: ranking.value !== metadata.tabValue || ranking.gender !== metadata.tabGender
		}
	);

	useEffect(() => {
		if (metadata.changeTabs && !stopChangingTabs) {
			// Start the interval
			intervalRef.current = setInterval(() => {
				const currentIndex = LEADERBOARD_TAB_OPTIONS.findIndex(
					(option) => option.value === ranking.value && option.gender === ranking.gender
				);
				const nextIndex = (currentIndex + 1) % LEADERBOARD_TAB_OPTIONS.length;
				const nextTab = LEADERBOARD_TAB_OPTIONS[nextIndex];

				if (nextTab) {
					setRanking({
						value: nextTab.value,
						gender: nextTab.gender
					});
				}
			}, metadata.secondsInBetweenChanges * 1000);
		}

		// Cleanup function to clear the interval when the component unmounts or dependencies change
		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
				intervalRef.current = null;
			}
		};
	}, [ranking]);

	// Custom function to stop the interval
	const stopInterval = () => {
		setStopChangingTabs(true);
		if (intervalRef.current) {
			clearInterval(intervalRef.current);
			intervalRef.current = null;
		}
	};

	return (
		<div className="rounded-xl bg-white shadow-sm">
			<h2 className="border-b border-gray-200 p-4 text-sm font-bold" tabIndex={0}>
				PPA Leaderboard
			</h2>
			<div className="flex justify-between border-b border-gray-200 px-2">
				{LEADERBOARD_TAB_OPTIONS.map((option) => {
					const isCurrentSelected = Number(ranking?.value) === option.value && ranking?.gender === option.gender;
					const tabItemClasses = clsx(
						'cursor-pointer border-b-2 border-transparent px-2 py-3 text-sm font-semibold uppercase text-gray-500 hover:text-experiment',
						{
							'!border-experiment !text-experiment': isCurrentSelected,
							'border-b-2 border-transparent': !isCurrentSelected
						}
					);

					return (
						<div
							className={tabItemClasses}
							key={`${option.value}_${option.gender}`}
							onClick={() => {
								stopInterval();
								setRanking({ value: option.value, gender: option.gender });
							}}
							tabIndex={0}
							role="button"
						>
							{option.label}
						</div>
					);
				})}
			</div>
			<div className="flex h-[512px] flex-col pt-6">
				<>
					{isFetching ? (
						<div className="flex h-full flex-1 items-center justify-center">
							<FontAwesomeIcon size="lg" icon={faSpinnerThird} spin />
						</div>
					) : !rankingsData || rankingsData.length === 0 ? (
						<div className="flex h-full flex-1 items-center justify-center text-sm text-gray-600">No data.</div>
					) : (
						<div className="relative flex-1">
							<div className="flex items-center px-4">
								<div className="w-11 shrink-0 text-left text-xs font-normal text-gray-500">Rank</div>
								<div className="flex-1 pl-5 pr-7 text-xs font-normal text-gray-500">Player</div>
								<div className="flex-1 pl-9 text-xs font-normal text-gray-500">Country</div>
								<div className="w-10 shrink-0 text-right text-xs font-normal text-gray-500">Points</div>
							</div>
							<div className="flex flex-col divide-y divide-gray-200 overflow-auto" role="table">
								{rankingsData.map((item) => (
									<div
										key={item.player_uuid}
										aria-label={`Player: ${item.player_first_name} ${item.player_last_name}, Rank: ${item.ranking}`}
										className="hover:!bg-gray-200"
									>
										<Link href={`/players/${item.player_slug || item.player_uuid}`} aria-hidden="true">
											<div className="flex items-center px-4 py-2">
												<div className="w-8 shrink-0 text-left text-xs font-bold">
													{item.is_tied ? `T${item.ranking}` : item.ranking}
												</div>
												<div className="mr-2 flex items-center justify-center">
													<Avatar imageUrl={item.profile_image} imageAltText="" size="xs" />
												</div>
												<div className="mr-1 w-[126px] truncate text-sm text-gray-900">
													{`${item.player_first_name.charAt(0).toUpperCase()}.`}
													<span className="mr-1" />
													<b>{item.player_last_name}</b>
													<span className="mr-1" />
													{item.name_sufix}
												</div>
												<div className="w-5">
													{item.player_country_two_digit_abbreviation && (
														<img
															src={getCDNURL(
																`${process.env.NEXT_PUBLIC_IMAGE_CDN}/circle-flags/${item.player_country_two_digit_abbreviation.toLowerCase()}.svg`,
																40,
																40
															)}
															width={20}
															alt=""
														/>
													)}
												</div>
												<div className="w-[58px] shrink-0 text-right text-xs font-bold">
													{Intl.NumberFormat('en-US').format(item.points)}
												</div>
											</div>
										</Link>
									</div>
								))}
							</div>
						</div>
					)}
					<Link
						href="/rankings"
						className="flex items-center justify-center border-t border-gray-200 py-4 text-sm font-semibold text-experiment underline-offset-4 hover:underline"
					>
						View Full Leaderboard
					</Link>
				</>
			</div>
		</div>
	);
};

export default RenderRankings;
