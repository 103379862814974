import React from 'react';

import { FeaturedPartnersMetadataInterface } from '@/utils/helpers/types';

import { FeaturedPartners } from '../FeaturedPartners';

const RenderFeaturedPartners = ({ partners, title }: FeaturedPartnersMetadataInterface) => {
	return <FeaturedPartners title={title} data={partners} />;
};

export default RenderFeaturedPartners;
