import React from 'react';

import { SocialMediaMetadataInterface } from '@/utils/helpers/types';

import { SocialMedia } from '../SocialMedia';

const RenderSocialMedia = ({ socialMedias, title }: SocialMediaMetadataInterface) => {
	return <SocialMedia title={title} data={socialMedias} />;
};

export default RenderSocialMedia;
