import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import Advertisement from '@/components/RenderAd/Advertisement';
import { RenderBlock } from '@/components/RenderBlock';
import { RenderCarousel } from '@/components/RenderCarousel';
import LoadMoreButton from '@/components/RenderContentArea/LoadMoreButton';
import { RenderExternalNews } from '@/components/RenderExternalNews';
import { RenderImage } from '@/components/RenderImage';
import { RenderNews } from '@/components/RenderNews';
import { RenderVideo } from '@/components/RenderVideo';
import { ColumnInterfaceExtended } from '@/utils/actions/homepage/getHomepageStructure';
import { ColumnWidgetTypes } from '@/utils/helpers/types';

export interface RenderContentAreaProps extends ColumnInterfaceExtended {
	currentPage: number;
	setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
	isLoading: boolean;
}

const RenderContentArea = ({ ipAddress, type, widgets, nextPage, currentPage, setCurrentPage, isLoading }: RenderContentAreaProps) => {
	return (
		<div key={type} className="w-full max-w-screen-sm flex-1">
			<div className="flex w-full flex-col gap-4 px-0.5 pt-4 sm:mx-auto sm:w-[640px] sm:px-0 sm:pt-6">
				{widgets.map((widget) => (
					<React.Fragment key={widget.uuid}>
						{widget.type === ColumnWidgetTypes.BLOCK ? (
							<RenderBlock {...widget.metadata} />
						) : widget.type === ColumnWidgetTypes.NEWS && widget.metadata.prefetchedData ? (
							<RenderNews newsType={widget.metadata.newsType} {...widget.metadata.prefetchedData} />
						) : widget.type === ColumnWidgetTypes.VIDEO ? (
							<RenderVideo {...widget.metadata} />
						) : widget.type === ColumnWidgetTypes.IMAGE ? (
							<RenderImage {...widget.metadata} />
						) : widget.type === ColumnWidgetTypes.EXTERNAL_NEWS ? (
							<RenderExternalNews
								url={widget.metadata.url}
								newsType={widget.metadata.newsType}
								{...(widget.metadata.prefetchedData ? widget.metadata.prefetchedData : widget.metadata)}
							/>
						) : widget.type === ColumnWidgetTypes.MIDDLE_ADVERTISEMENT ? (
							<Advertisement
								height={+widget.metadata.height}
								image={widget.metadata.prefetchedData}
								ipAddress={ipAddress}
								pageId={100000}
								place={widget.metadata.place ? +widget.metadata.place : 0}
								width={+widget.metadata.width}
								zoneId={widget.metadata.zoneId}
							/>
						) : widget.type === ColumnWidgetTypes.CAROUSEL && widget.metadata.prefetchedData ? (
							<RenderCarousel carouselNews={widget.metadata.prefetchedData} />
						) : null}
					</React.Fragment>
				))}
			</div>
			{nextPage !== 'no next page' && (
				<div className="my-8 flex w-full justify-center">
					{isLoading ? (
						<div className="flex h-[36px] w-full items-center justify-center">
							<FontAwesomeIcon icon={faSpinnerThird} spin className="text-gray-500" size="xl" />
						</div>
					) : (
						<LoadMoreButton currentPage={currentPage} setCurrentPage={setCurrentPage} />
					)}
				</div>
			)}
		</div>
	);
};

export default RenderContentArea;
