/* eslint-disable no-nested-ternary */
import Link from 'next/link';
import React from 'react';

import getPickleballCentralCDNURL from '@/utils/helpers/getPickleballCentralCDNURL';
import { NewsFormatType } from '@/utils/helpers/types';

import { NewsCard } from '../NewsCard';
import { SmallNewsCard } from '../SmallNewsCard';

export interface RenderExternalNewsProps {
	url: string;
	newsType: NewsFormatType;
	title?: string;
	image?: string;
	description?: string;
	datePublished?: string;
	author?: string;
}

const RenderExternalNews = ({ url, title, description, image, author, datePublished, newsType }: RenderExternalNewsProps) => {
	if (title && image) {
		return (
			<Link className="cursor-pointer hover:opacity-80" href={url} rel="noopener noreferrer" target="_blank">
				{newsType === 'format-a' ? (
					<NewsCard
						url={url}
						image={getPickleballCentralCDNURL(image, 650, 260)}
						description={description}
						title={title}
						author={author}
						externalNews
						{...(datePublished ? { publishDateDisplayed: datePublished } : {})}
					/>
				) : (
					<SmallNewsCard
						url={url}
						image={getPickleballCentralCDNURL(image, 650, 260)}
						description={description}
						title={title}
						author={author}
						externalNews
						{...(datePublished ? { publishDateDisplayed: datePublished } : {})}
					/>
				)}
			</Link>
		);
	}

	return null;
};

export default RenderExternalNews;
