/* eslint-disable @next/next/no-img-element */
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

import { PartnerInterface } from '@/utils/helpers/types';

import styles from './FeaturedPartners.module.css';

export interface FeaturedPartnersProps {
	data: PartnerInterface[];
	title?: string;
}

const FeaturedPartners = ({ data, title }: FeaturedPartnersProps) => {
	const wrapperClasses = clsx(styles.wrapper);
	const headerClasses = clsx(styles.header);
	const linkContainerClasses = clsx(styles.link__container);
	const imageClasses = clsx(styles.image);

	return (
		<div className={wrapperClasses}>
			<h2 className={headerClasses}>{title || 'Our Featured Partners'}</h2>
			<div className={linkContainerClasses}>
				{data.map((dataObject) => {
					return (
						<Link key={dataObject.name} href={dataObject.link} rel="noopener noreferrer" target="_blank" className={imageClasses}>
							<img width="120" height="120" src={dataObject.image} alt={dataObject.name} />
						</Link>
					);
				})}
			</div>
		</div>
	);
};

export default FeaturedPartners;
