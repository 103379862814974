'use client';

import React, { useEffect, useState } from 'react';

import { getHomepageStructure, StructureInterface } from '@/utils/actions/homepage/getHomepageStructure';

import { RenderContentArea } from '../RenderContentArea';
import { RenderSidebar } from '../RenderSidebar';
import insertAdsPlacements from './insertAdsPlacements';

export interface RenderContentProps {
	structure: StructureInterface;
	adsPlacesHashmap: Map<string, number>;
}

const RenderContent = ({ structure, adsPlacesHashmap }: RenderContentProps) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [currentStructure, setCurrentStructure] = useState(structure);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const getAndSetMoreHomepageStructureWidgets = async () => {
			setIsLoading(true);
			const { data: newStructure, statusCode } = await getHomepageStructure({
				currentPage: currentPage,
				numberOfDifferentAds: structure.numberOfDifferentImagesInBannerAd
			});

			if (statusCode === 200 && newStructure) {
				insertAdsPlacements(newStructure, adsPlacesHashmap);

				setCurrentStructure({
					sidebar1: {
						...newStructure.sidebar1,
						widgets: [...currentStructure.sidebar1.widgets, ...newStructure.sidebar1.widgets]
					},
					contentArea: {
						...newStructure.contentArea,
						widgets: [...currentStructure.contentArea.widgets, ...newStructure.contentArea.widgets]
					},
					sidebar2: {
						...newStructure.sidebar2,
						widgets: [...currentStructure.sidebar2.widgets, ...newStructure.sidebar2.widgets]
					},
					numberOfDifferentImagesInBannerAd: newStructure.numberOfDifferentImagesInBannerAd,
					ipAddress: newStructure.ipAddress ? newStructure.ipAddress : currentStructure.ipAddress
				});
			}
			setIsLoading(false);
		};

		if (currentPage > 1) {
			getAndSetMoreHomepageStructureWidgets();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	return (
		<div className="flex justify-center gap-5">
			<RenderSidebar {...currentStructure.sidebar1} ipAddress={currentStructure.ipAddress} className="lg:flex" />
			<RenderContentArea
				{...currentStructure.contentArea}
				ipAddress={currentStructure.ipAddress}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
				isLoading={isLoading}
			/>
			<RenderSidebar {...currentStructure.sidebar2} ipAddress={currentStructure.ipAddress} />
		</div>
	);
};

export default RenderContent;
