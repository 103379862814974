import Image, { ImageProps } from 'next/image';
import React, { useEffect, useState } from 'react';

interface ImageWithFallbackProps extends ImageProps {
	fallbackSrc?: string;
	alt: string;
}

const ImageWithFallback = (props: ImageWithFallbackProps) => {
	const { src, fallbackSrc = 'https://cdn.pickleball.com/placeholder/pickleball_transparent_square.png', ...rest } = props;
	const [imgSrc, setImgSrc] = useState(src);

	useEffect(() => {
		if (typeof src === 'string' && (src.startsWith('/') || src.startsWith('http'))) {
			setImgSrc(src);
		} else {
			setImgSrc(fallbackSrc);
		}
	}, [src, fallbackSrc]);

	return (
		<Image
			src={imgSrc}
			onError={() => {
				setImgSrc(fallbackSrc);
			}}
			{...rest}
			alt={props.alt}
		/>
	);
};

export default React.memo(ImageWithFallback);
