'use client';

import { MatchCardV2 } from '@pickleballinc/react-ui';
import Link from 'next/link';
import React from 'react';

import { MatchExtended, MatchInfo } from '@/modules/ticker/types';
import { formatMatchInfo } from '@/utils/helpers/formatMatchInfo';

export interface RenderMatchesProps {
	initialMatches: MatchInfo[];
	title: string;
}

const RenderMatches = ({ initialMatches, title }: RenderMatchesProps) => {
	if (initialMatches.length === 0) return null;

	const formattedResults: MatchExtended[] = formatMatchInfo({
		matchInfos: initialMatches,
		imagesWidth: 22,
		imagesHeight: 22
	});

	return (
		<div className="flex w-full flex-col gap-4 rounded-lg bg-white pb-1">
			<h3 className="w-full text-left text-sm font-bold leading-5 tracking-normal">{title}</h3>
			<div className={`grid gap-2 ${formattedResults.length > 1 ? 'grid-cols-1 sm:grid-cols-2' : 'grid-cols-1'}`}>
				{formattedResults.map((result) => (
					<Link key={result.id} className="cursor-pointer hover:opacity-80" href={`/results/match/${result.id}`}>
						<MatchCardV2 match={result} compact shortenName forTicker={false} />
					</Link>
				))}
			</div>
		</div>
	);
};

export default RenderMatches;
