'use client';

import { MatchCardV2 } from '@pickleballinc/react-ui';
import Link from 'next/link';
import React, { useMemo, useState } from 'react';

import { useRTE } from '@/contexts/rte/RTEProvider';
import { RTEMatchInfo } from '@/contexts/rte/types';
import { MatchExtended, MatchInfo } from '@/modules/ticker/types';
import { formatMatchInfo } from '@/utils/helpers/formatMatchInfo';

export interface RenderMatchesLiveProps {
	initialMatches: MatchInfo[];
	title: string;
}

const RenderMatchesLive = ({ initialMatches, title }: RenderMatchesLiveProps) => {
	const { matches } = useRTE();

	const [matchesData, setMatchesData] = useState<MatchInfo[]>(initialMatches);
	const [matchKeys, setMatchKeys] = useState<Record<string, number>>(() =>
		initialMatches.reduce((acc, match) => ({ ...acc, [match.matchUuid]: 0 }), {})
	);

	useMemo(() => {
		setMatchesData((matchesPrev) => {
			const matchesDataCopy = [...matchesPrev];
			const newMatchKeys = { ...matchKeys };

			matches.forEach((newMatch) => {
				const matchIndex = matchesPrev.findIndex((match) => match.matchUuid === newMatch.matchUuid);

				if (matchIndex !== -1) {
					const matchToUpdate = matchesDataCopy[matchIndex];

					if (matchToUpdate) {
						// Check if at least one field in newMatch is different from matchToUpdate
						const hasDifferences = (Object.keys(newMatch) as (keyof RTEMatchInfo)[]).some(
							(key) => newMatch[key] !== matchToUpdate[key as keyof MatchInfo]
						);

						// Create a new object by removing any `undefined` values from `newMatch` and adding `changeHappened` only if there are differences
						const filteredNewMatch = Object.fromEntries(
							Object.entries({ ...newMatch, changeHappened: hasDifferences }).filter(([_, value]) => value !== undefined)
						);

						matchesDataCopy[matchIndex] = { ...matchToUpdate, ...filteredNewMatch } as MatchInfo;

						if (hasDifferences) {
							// Increment the key to force re-render of MatchCardV2
							newMatchKeys[newMatch.matchUuid] = (newMatchKeys[newMatch.matchUuid] || 0) + 1;
						}
					}
				}
			});

			setMatchKeys(newMatchKeys); // Update match keys for changed matches
			return [...matchesDataCopy];
		});
	}, [matches]);

	if (matchesData.length === 0) return null;

	const formattedResults: MatchExtended[] = formatMatchInfo({
		matchInfos: matchesData,
		imagesWidth: 22,
		imagesHeight: 22
	});

	return (
		<div className="flex w-full flex-col gap-4 rounded-lg bg-white pb-1">
			<h3 className="w-full text-left text-sm font-bold leading-5 tracking-normal">{title}</h3>
			<div className={`grid gap-2 ${formattedResults.length > 1 ? 'grid-cols-1 sm:grid-cols-2' : 'grid-cols-1'}`}>
				{formattedResults.map((result) => (
					<Link key={result.id} className="cursor-pointer hover:opacity-80" href={`/results/match/${result.id}`}>
						<MatchCardV2
							key={`matchcardv2-${result.id}-${matchKeys[result.id || '']}`} // Unique key for each change
							match={result}
							compact
							shortenName
							forTicker={false}
							animation={result.changeHappened ? 'glow' : undefined}
						/>
					</Link>
				))}
			</div>
		</div>
	);
};

export default RenderMatchesLive;
