'use client';

import { ResultCard } from '@pickleballinc/react-ui';
import Link from 'next/link';
import React, { useMemo, useState } from 'react';

import { useRTE } from '@/contexts/rte/RTEProvider';
import { RTEMatchInfo } from '@/contexts/rte/types';
import { MatchExtended, MatchInfo } from '@/modules/ticker/types';
import { formatMatchInfo } from '@/utils/helpers/formatMatchInfo';

export interface RenderResultCardLiveProps {
	initialMatches: MatchInfo[];
	rounded?: boolean;
}

const RenderResultCardLive = ({ initialMatches, rounded }: RenderResultCardLiveProps) => {
	const { matches } = useRTE();

	const [matchesData, setMatchesData] = useState<MatchInfo[]>(initialMatches);
	const [matchKeys, setMatchKeys] = useState<Record<string, number>>(() =>
		initialMatches.reduce((acc, match) => ({ ...acc, [match.matchUuid]: 0 }), {})
	);

	useMemo(() => {
		setMatchesData((matchesPrev) => {
			const matchesDataCopy = [...matchesPrev];
			const newMatchKeys = { ...matchKeys };

			matches.forEach((newMatch) => {
				const matchIndex = matchesPrev.findIndex((match) => match.matchUuid === newMatch.matchUuid);

				if (matchIndex !== -1) {
					const matchToUpdate = matchesDataCopy[matchIndex];

					if (matchToUpdate) {
						// Check if at least one field in newMatch is different from matchToUpdate
						const hasDifferences = (Object.keys(newMatch) as (keyof RTEMatchInfo)[]).some(
							(key) => newMatch[key] !== matchToUpdate[key as keyof MatchInfo]
						);

						// Create a new object by removing any `undefined` values from `newMatch` and adding `changeHappened` only if there are differences
						const filteredNewMatch = Object.fromEntries(
							Object.entries({ ...newMatch, changeHappened: hasDifferences }).filter(([_, value]) => value !== undefined)
						);

						matchesDataCopy[matchIndex] = { ...matchToUpdate, ...filteredNewMatch } as MatchInfo;

						if (hasDifferences) {
							newMatchKeys[newMatch.matchUuid] = (newMatchKeys[newMatch.matchUuid] || 0) + 1;
						}
					}
				}
			});

			setMatchKeys(newMatchKeys); // Update match keys for changed matches
			return [...matchesDataCopy];
		});
	}, [matches]);

	if (matchesData.length === 0) return null;

	const duoMatch = matchesData && matchesData[0] && matchesData[0].teamOnePlayerTwoUuid;

	const formattedResults: MatchExtended[] = formatMatchInfo({
		matchInfos: matchesData,
		imagesWidth: duoMatch ? 22 : 38,
		imagesHeight: duoMatch ? 22 : 38,
		matchCardType: 'RESULT'
	});

	return (
		<div className="-mx-4 flex w-[calc(100%+32px)] flex-col gap-4 rounded-lg sm:-mx-6 sm:w-[640px]">
			{formattedResults.map((result) => (
				<Link key={result.id} className="cursor-pointer hover:opacity-80" href={`/results/match/${result.id}`}>
					<ResultCard
						key={`resultcard-${result.id}-${matchKeys[result.id || '']}`} // Unique key for each change
						match={result}
						rounded={rounded}
						animation={result.changeHappened ? 'glow' : undefined}
					/>
				</Link>
			))}
		</div>
	);
};

export default RenderResultCardLive;
