'use client';

import Link from 'next/link';
import React from 'react';

import MatchCardV3 from '@/components/MatchCardV3';
import { MatchExtended, MatchInfo } from '@/modules/ticker/types';
import { formatMatchInfo } from '@/utils/helpers/formatMatchInfo';

export interface RenderMatchCardV3Props {
	initialMatches: MatchInfo[];
}

const RenderMatchCardV3 = ({ initialMatches }: RenderMatchCardV3Props) => {
	if (initialMatches.length === 0) return null;

	const duoMatch = initialMatches && initialMatches[0] && initialMatches[0].teamOnePlayerTwoUuid;

	const formattedResults: MatchExtended[] = formatMatchInfo({
		matchInfos: initialMatches,
		imagesWidth: duoMatch ? 80 : 100,
		imagesHeight: duoMatch ? 80 : 100,
		matchCardType: 'MATCHV3'
	});

	return (
		<div className="-mx-4 flex w-[calc(100%+32px)] flex-col gap-4 rounded-lg sm:mx-[unset] sm:w-[unset]">
			{formattedResults.map((result) => (
				<Link key={result.id} className="cursor-pointer" href={`/results/match/${result.id}`}>
					<MatchCardV3 match={result} />
				</Link>
			))}
		</div>
	);
};

export default RenderMatchCardV3;
