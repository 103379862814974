/* eslint-disable no-nested-ternary */
import { faFacebook, faInstagram, faSnapchat, faTiktok, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import Link from 'next/link';
import React from 'react';

import { SocialMediaRowInterface } from '@/utils/helpers/types';

import styles from './SocialMedia.module.css';

export interface SocialMediaProps {
	data: SocialMediaRowInterface[];
	title?: string;
}

const SocialMedia = ({ data, title }: SocialMediaProps) => {
	const wrapperClasses = clsx(styles.wrapper);
	const headerClasses = clsx(styles.header);
	const linkClasses = clsx(styles.link);
	const iconContainerClasses = clsx(styles.icon__container);
	const iconWrapperClasses = clsx(styles.icon__wrapper);
	const nameClasses = clsx(styles.name);
	const borderContainerClasses = clsx(styles.border__container);
	const borderClasses = clsx(styles.border);

	return (
		<div className={wrapperClasses}>
			<h2 className={headerClasses}>{title || 'Follow us'}</h2>
			<ul>
				{data
					.filter((dataObject) => dataObject.link !== '')
					.map((dataObject, index) => {
						return (
							// eslint-disable-next-line react/no-array-index-key
							<li key={index}>
								<Link className={linkClasses} href={dataObject.link} rel="noopener noreferrer" target="_blank">
									<div className={iconContainerClasses}>
										<div className={iconWrapperClasses}>
											{dataObject.name === 'Facebook' ? (
												<FontAwesomeIcon icon={faFacebook} color="#000000" size="lg" />
											) : dataObject.name === 'Instagram' ? (
												<FontAwesomeIcon icon={faInstagram} color="#000100" size="lg" />
											) : dataObject.name === 'Snapchat' ? (
												<FontAwesomeIcon icon={faSnapchat} color="#000000" size="lg" />
											) : dataObject.name === 'TikTok' ? (
												<FontAwesomeIcon icon={faTiktok} color="#000000" size="lg" />
											) : dataObject.name === 'X/Twitter' ? (
												<FontAwesomeIcon icon={faXTwitter} color="#000000" size="lg" />
											) : dataObject.name === 'YouTube' ? (
												<FontAwesomeIcon icon={faYoutube} color="#000000" size="lg" />
											) : null}
										</div>
										<div className={nameClasses}>{dataObject.name}</div>
									</div>
								</Link>
								{index !== data.filter((dataObject) => dataObject.link !== '').length - 1 && (
									<div className={borderContainerClasses}>
										<div className={borderClasses} />
									</div>
								)}
							</li>
						);
					})}
			</ul>
		</div>
	);
};

export default SocialMedia;
