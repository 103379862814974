import React from 'react';

import type { VideoInterface } from '@/utils/helpers/types';

import { VideoCard } from '../VideoCard';

const RenderVideo = ({ title, featuredImageURL, videoURL }: VideoInterface) => {
	return <VideoCard image={featuredImageURL} title={title} videoURL={videoURL} />;
};

export default RenderVideo;
