/* eslint-disable @next/next/no-img-element */
import clsx from 'clsx';
import React from 'react';

import getCDNURL from '@/utils/helpers/getCDNURL';

import styles from './TourTitle.module.css';

type ImagePositionTypes = 'left' | 'right' | 'no-image';

export interface TourTitleProps {
	image?: string;
	imageAltText?: string;
	imagePosition?: ImagePositionTypes;
	title: string;
	altTitle?: string;
	activateNoBorder?: boolean;
	backgroundColor?: string;
	link?: string;
}

const TourTitle = ({ image, imageAltText, imagePosition = 'left', title, altTitle, activateNoBorder, backgroundColor, link }: TourTitleProps) => {
	const wrapperClasses = clsx(styles.wrapper, {
		[styles['wrapper--image-position-right'] as string]: imagePosition === 'right',
		[styles['wrapper--activate-no-border'] as string]: activateNoBorder
	});
	const imageClasses = clsx(styles.image);
	const titlesContainerClasses = clsx(styles.titles__container);
	const titleClasses = clsx(styles.title, {
		[styles['title--gray-text'] as string]: imagePosition === 'no-image' && backgroundColor?.toUpperCase() === '#FFFFFF',
		[styles['title--black-text'] as string]:
			(imagePosition === 'no-image' && backgroundColor?.toUpperCase() === '#E8E8F2') || imagePosition !== 'no-image',
		[styles['title--white-text'] as string]:
			imagePosition === 'no-image' && backgroundColor?.toUpperCase() !== '#FFFFFF' && backgroundColor?.toUpperCase() !== '#E8E8F2',
		[styles['title--small'] as string]: altTitle
	});
	const altTitleClasses = clsx(styles.alt__title);
	const headerClasses = clsx(styles.header, {
		[styles['header--activate-no-border'] as string]: activateNoBorder
	});
	const linkClasses = clsx(styles.link, {
		[styles['link--with-href'] as string]: link,
		[styles['link--gray-text'] as string]: backgroundColor?.toUpperCase() === '#FFFFFF',
		[styles['link--black-text'] as string]: backgroundColor?.toUpperCase() === '#E8E8F2',
		[styles['link--white-text'] as string]: backgroundColor?.toUpperCase() !== '#FFFFFF' && backgroundColor?.toUpperCase() !== '#E8E8F2'
	});

	return imagePosition !== 'no-image' ? (
		<div className={wrapperClasses}>
			{image && (
				<img
					alt={imageAltText || title}
					src={
						getCDNURL(image, 40, 40) ||
						`${getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/assets/images/placeholder.png`, 40, 40)}`
					}
					className={imageClasses}
				/>
			)}
			<div className={titlesContainerClasses}>
				<h2 className={titleClasses}>{title}</h2>
				<p className={altTitleClasses}>{altTitle}</p>
			</div>
		</div>
	) : (
		<div
			style={{
				background: backgroundColor === '#F05235' ? '#B93815' : backgroundColor === '#40BF40' ? '#087443' : backgroundColor || ''
			}}
			className={headerClasses}
		>
			<h2>
				{link ? (
					<a href={link} rel="noopener noreferrer" target="_blank" className={linkClasses}>
						{title}
					</a>
				) : (
					<span className={linkClasses}>{title}</span>
				)}
			</h2>
		</div>
	);
};

export default TourTitle;
