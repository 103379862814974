import React from 'react';

import Advertisement from '@/components/RenderAd/Advertisement';
import RenderAd from '@/components/RenderAd/RenderAd';
import { PlacementInterface } from '@/utils/helpers/types';

export interface RenderFloatingAdProps {
	className?: string;
	height: number;
	image?: PlacementInterface;
	ipAddress?: string;
	pageId: number;
	place: number;
	width: number;
	zoneId: string;
	isFetchedSs?: boolean;
}

const RenderFloatingAd = ({ className, height, image, ipAddress, pageId, place, width, zoneId, isFetchedSs }: RenderFloatingAdProps) => {
	return (
		<div className="sticky top-4 w-full">
			<div className="w-full overflow-hidden">
				{isFetchedSs ? (
					<Advertisement
						className={className}
						height={height}
						image={image}
						ipAddress={ipAddress}
						pageId={pageId}
						place={place}
						width={width}
						zoneId={zoneId}
					/>
				) : (
					<RenderAd
						className={className}
						height={height}
						ipAddress={ipAddress}
						pageId={pageId}
						place={place}
						width={width}
						zoneId={zoneId}
					/>
				)}
			</div>
		</div>
	);
};

export default RenderFloatingAd;
