'use client';

import clsx from 'clsx';
import Link from 'next/link';
import React, { useMemo, useState } from 'react';

import MatchCardV3 from '@/components/MatchCardV3';
import styles from '@/components/RenderMatchCardV3/RenderMatchCardV3.module.css';
import { useRTE } from '@/contexts/rte/RTEProvider';
import { RTEMatchInfo } from '@/contexts/rte/types';
import { MatchExtended, MatchInfo } from '@/modules/ticker/types';
import { formatMatchInfo } from '@/utils/helpers/formatMatchInfo';

export interface RenderMatchCardV3LiveProps {
	initialMatches: MatchInfo[];
}

const RenderMatchCardV3Live = ({ initialMatches }: RenderMatchCardV3LiveProps) => {
	const { matches } = useRTE();

	const [matchesData, setMatchesData] = useState<MatchInfo[]>(initialMatches);
	const [matchKeys, setMatchKeys] = useState<Record<string, number>>(() =>
		initialMatches.reduce((acc, match) => ({ ...acc, [match.matchUuid]: 0 }), {})
	);

	useMemo(() => {
		setMatchesData((matchesPrev) => {
			const matchesDataCopy = [...matchesPrev];
			const newMatchKeys = { ...matchKeys };

			matches.forEach((newMatch) => {
				const matchIndex = matchesPrev.findIndex((match) => match.matchUuid === newMatch.matchUuid);

				if (matchIndex !== -1) {
					const matchToUpdate = matchesDataCopy[matchIndex];

					if (matchToUpdate) {
						// Check if at least one field in newMatch is different from matchToUpdate
						const hasDifferences = (Object.keys(newMatch) as (keyof RTEMatchInfo)[]).some(
							(key) => newMatch[key] !== matchToUpdate[key as keyof MatchInfo]
						);

						// Create a new object by removing any `undefined` values from `newMatch` and adding `changeHappened` only if there are differences
						const filteredNewMatch = Object.fromEntries(
							Object.entries({ ...newMatch, changeHappened: hasDifferences }).filter(([_, value]) => value !== undefined)
						);

						matchesDataCopy[matchIndex] = { ...matchToUpdate, ...filteredNewMatch } as MatchInfo;

						if (hasDifferences) {
							// Increment the key to force re-render of MatchCardV3 div wrapper
							newMatchKeys[newMatch.matchUuid] = (newMatchKeys[newMatch.matchUuid] || 0) + 1;
						}
					}
				}
			});

			setMatchKeys(newMatchKeys); // Update match keys for changed matches
			return [...matchesDataCopy];
		});
	}, [matches]);

	if (matchesData.length === 0) return null;

	const duoMatch = matchesData && matchesData[0] && matchesData[0].teamOnePlayerTwoUuid;

	const formattedResults: MatchExtended[] = formatMatchInfo({
		matchInfos: matchesData,
		imagesWidth: duoMatch ? 80 : 100,
		imagesHeight: duoMatch ? 80 : 100,
		matchCardType: 'MATCHV3'
	});

	return (
		<>
			{formattedResults.map((result) => {
				const innerWrapperClasses = clsx('relative -mx-4 flex w-[calc(100%+32px)] flex-col gap-4 rounded-lg sm:mx-[unset] sm:w-[unset]', {
					[styles['glow-animation'] as string]: result.changeHappened
				});

				return (
					<div key={`wrapper-matchcardv3-${result.id}-${matchKeys[result.id || '']}`} className={innerWrapperClasses}>
						<Link className="cursor-pointer hover:opacity-80" href={`/results/match/${result.id}`}>
							<MatchCardV3 match={result} />
						</Link>
					</div>
				);
			})}
		</>
	);
};

export default RenderMatchCardV3Live;
