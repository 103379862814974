/* eslint-disable @next/next/no-img-element */
import clsx from 'clsx';
import React from 'react';

import getCDNURL from '@/utils/helpers/getCDNURL';

import styles from './ImageCard.module.css';

export interface ImageCardProps {
	imageURL: string;
	imageAltText?: string;
	description?: string;
}

const ImageCard = ({ imageURL, imageAltText, description }: ImageCardProps) => {
	const wrapperClasses = clsx(styles.wrapper);
	const imageClasses = clsx(styles.image, {
		[styles['image--no-description'] as string]: !description
	});
	const contentClasses = clsx(styles.content);
	const descriptionClasses = clsx(styles.description);

	return (
		<div className={wrapperClasses}>
			<img
				className={imageClasses}
				style={{ objectFit: 'cover' }}
				alt={imageAltText || description || 'Image'}
				src={
					getCDNURL(imageURL, 640, 335) ||
					`${getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/assets/images/placeholder.png`, 640, 640)}`
				}
			/>
			{description && (
				<div className={contentClasses}>
					<h3 className={descriptionClasses}>{description}</h3>
				</div>
			)}
		</div>
	);
};

export default ImageCard;
