import { Button } from '@pickleballinc/react-ui';
import clsx from 'clsx';
import React from 'react';

import { ButtonInterface } from '@/utils/helpers/types';

import styles from './ButtonsContainer.module.css';

export interface ButtonsContainerProps {
	buttons: ButtonInterface[];
}

const ButtonsContainer = ({ buttons }: ButtonsContainerProps) => {
	const wrapperClasses = clsx(styles.wrapper);
	const buttonClasses = clsx(styles.button);

	return (
		<div className={wrapperClasses}>
			{buttons.map((button) => {
				return (
					<a key={button.id} href={button.link} rel="noopener noreferrer" target="_blank">
						<Button size="sm" variant="secondary" className={buttonClasses}>
							{button.text}
						</Button>
					</a>
				);
			})}
		</div>
	);
};

export default ButtonsContainer;
