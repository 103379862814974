import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import ImageWithFallback from '@/components/ImageWithFallback/ImageWithFallback';
import getCDNURL from '@/utils/helpers/getCDNURL';
import { PlayerResponse } from '@/utils/helpers/types';

interface FeaturedPlayerProps {
	player: PlayerResponse;
	lastFeatured: boolean;
}

const FeaturedPlayer = ({ player, lastFeatured }: FeaturedPlayerProps) => {
	const sponsorClasses = clsx('flex h-[46px] flex-row items-center justify-between', {
		'border-b border-solid border-gray-200': lastFeatured
	});

	const countryAbbreviation = player.representingCountryAbbreviation || player.countryAbbreviation || '';
	const countryCode = player.representingCountryAbbreviation2Digit || player.countryAbbreviation2Digit;
	const countryFlag = countryCode ? `${process.env.NEXT_PUBLIC_IMAGE_CDN}/circle-flags/${countryCode.toLowerCase()}.svg` : '';

	const profileImageSrc = player.userImages.find((image) => image.type === 'headerImg')?.url || '';
	const sponsor = player.sponsors.find((img) => ['XL', 'L', 'M', 'S', 'Icon'].includes(img.imageSize)) || null;

	return (
		<div>
			<Link
				href={`/players/${player.slug || player.uuid}`}
				aria-hidden="true"
				className="relative flex h-[157px] min-w-[266px] flex-row overflow-hidden rounded-xl border border-solid bg-experiment"
				rel="noopener noreferrer"
			>
				<div
					className="absolute inset-0"
					style={{
						backgroundImage: `url(${getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/assets/images/shutterstock-1.png`, 800, 600)})`,
						backgroundPosition: '-288px -48px',
						backgroundSize: '340% 258%',
						backgroundRepeat: 'no-repeat'
					}}
				/>
				<div className="relative z-10  flex max-w-[100px] flex-col justify-center gap-7 ps-5 pt-1">
					<div className="flex flex-col gap-0">
						<div className="text-left text-base font-semibold leading-6 tracking-normal text-white">{player.firstName}</div>
						<div className="text-left text-xl font-bold leading-7 tracking-normal text-white">{player.lastName}</div>
					</div>
					<div className="flex flex-row items-center gap-2">
						<Image width="28" height="15" alt={countryAbbreviation} src={getCDNURL(countryFlag, 24, 13)} />
						<div className="text-left text-sm font-normal leading-5 tracking-normal text-white">{countryAbbreviation}</div>
					</div>
				</div>
				<div className="relative ml-6 h-[155px] w-full rounded-e-xl">
					<ImageWithFallback
						src={profileImageSrc}
						className="object-contain"
						fill
						fallbackSrc={`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/static/profile-image-pro.svg`}
						alt={`${player.firstName} ${player.lastName} Profile Image`}
					/>
				</div>
			</Link>
			<div className="flex h-[46px] flex-row items-center justify-between border-b border-solid border-gray-200">
				<div className="ps-0.5 text-left text-xs font-normal leading-4 tracking-normal text-gray-500">Ranking</div>
				<div className="pe-0.5 text-right text-xs font-bold leading-4 tracking-normal">
					{player.single365Rank && <>#{player.single365Rank}</>}
				</div>
			</div>
			<div
				className={`flex h-[46px] flex-row items-center justify-between ${(sponsor || lastFeatured) && 'border-b'}  border-solid border-gray-200`}
			>
				<div className="ps-0.5 text-left text-xs font-normal leading-4 tracking-normal text-gray-500">Points</div>
				<div className="pe-0.5 text-right text-xs font-bold leading-4 tracking-normal">{player.single365Points}</div>
			</div>
			{sponsor && sponsor.sponsorImage && (
				<div className={sponsorClasses}>
					<div className="ps-0.5 text-left text-xs font-normal leading-4 tracking-normal text-gray-500">Sponsor</div>
					<div className="pe-0.5">
						<a href={sponsor.sponsorUrl} rel="noopener noreferrer" target="_blank" className="hover:opacity-90">
							<Image
								width={200}
								height={50}
								alt={sponsor.sponsorName || 'Logo'}
								className="h-10 w-auto object-contain"
								fill={false}
								src={getCDNURL(sponsor.sponsorImage, 200, 200)}
							/>
						</a>
					</div>
				</div>
			)}
		</div>
	);
};

export default FeaturedPlayer;
