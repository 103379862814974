import Link from 'next/link';
import React, { Fragment } from 'react';

import FeaturedPlayer from '@/components/FeaturedPlayer/FeaturedPlayer';
import { PlayerResponse } from '@/utils/helpers/types';

export interface FeaturedPlayersContainerProps {
	title: string;
	players: PlayerResponse[];
}

const FeaturedPlayersContainer = ({ title, players }: FeaturedPlayersContainerProps) => {
	if (players.length === 0) return null;

	return (
		<div className="flex w-full flex-col items-center rounded-xl bg-white">
			<div className="w-full py-4 pl-[18px] text-left text-sm font-bold leading-5 tracking-normal">{title}</div>
			{players.map((featuredPlayer, index) => (
				<Fragment key={featuredPlayer.uuid}>
					<FeaturedPlayer player={featuredPlayer} lastFeatured={index === players.length - 1} />
					{index !== players.length - 1 && <div className="w-full border-t border-solid border-gray-200 pb-4" />}
				</Fragment>
			))}
			<Link className="py-4 text-sm font-semibold text-experiment hover:underline" href={`/players`}>
				See all
			</Link>
		</div>
	);
};

export default FeaturedPlayersContainer;
